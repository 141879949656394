import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import './CookieConsent.css';

function CookieConsent() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setShowBanner(true);
    } else if (cookiesAccepted === 'true') {
      initializeAnalytics();
    }

    // Listen for the custom event to show the cookie banner
    const handleShowBanner = () => setShowBanner(true);
    document.addEventListener('showCookieConsent', handleShowBanner);

    return () => {
      document.removeEventListener('showCookieConsent', handleShowBanner);
    };
  }, []);

  const initializeAnalytics = () => {
    ReactGA.initialize('G-4PG4WM9RWX');
    ReactGA.send('pageview');
  };

  const handleAccept = () => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    console.log("Keksit hyväksytty")
    localStorage.setItem('cookiesAccepted', 'true');
    console.log('Current cookiesAccepted state:', cookiesAccepted);
    initializeAnalytics();
    setShowBanner(false);
  };

  const handleRefuse = () => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    console.log('Keksit kielletty');
    localStorage.setItem('cookiesAccepted', 'false');
    console.log('Current cookiesAccepted state:', cookiesAccepted);
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <p>Tämä sivusto käyttää evästeitä parantaakseen käyttäjäkokemusta. <a style={{color: "white"}} href='../Evasteet.pdf'>Evästeseloste</a></p>
      <div className="cookie-consent-buttons">
        <button onClick={handleAccept}>Hyväksy</button>
        <button onClick={handleRefuse}>Kieltäydy</button>
      </div>
    </div>
  );
}

// Expose the showCookieBanner method globally
export function showCookieConsentBanner() {
  document.dispatchEvent(new Event('showCookieConsent'));
}

export default CookieConsent;
