import React from 'react';
import './Yhteystiedot.css'; // Import the new CSS file
import lamppu from './lamppu2.png'
import CookieConsent, { showCookieConsentBanner } from './CookieConsent';  // Updated import



function Yhteystiedot() {
  return (
    <>
      <main className="contact-main">
        <h2 className='contact-header'>Yhteystiedot</h2>
        <section className="yhteystiedot-contact-info">
          <div className="contact-box">
            <h3>Eristyspalvelu Kaasalainen Oy</h3>
            <p><strong>Y-tunnus:</strong> 2538016-9</p>
            <br></br>
            <p>Taipaleentie 139</p>
            <br></br>
            <p>16540 Mertie</p>
          </div>

          <div className="contact-box">
            <h3>Johanna Laine</h3>
            <p><strong>[Toimisto]</strong></p>
            <br></br>
            <p><a href="tel:0442417975">044 241 7975</a></p>
            <br></br>
            <p><a href="mailto:johanna@eristys.fi">johanna@eristys.fi</a></p>
          </div>

          <div className="contact-box">
            <h3>Juha Makkonen</h3>
            <p><strong>[Työnjohtaja]</strong></p>
            <br></br>
            <p><a href="tel:0505694389">050 569 4389</a></p>
            <br></br>
            <p><a href="mailto:juha@eristys.fi">juha@eristys.fi</a></p>
          </div>

          <div className="contact-box">
            <h3>Jarno Kaasalainen</h3>
            <p><strong>[Toimitusjohtaja]</strong></p>
            <br></br>
            <p><a href="tel:0401620404">040 162 0404</a></p>
            <br></br>
            <p><a href="mailto:info@eristys.fi">info@eristys.fi</a></p>
          </div>

          <button style={{ height: "3em", fontSize: "24px", marginTop: "5%", marginBottom: "5%", borderRadius: "50px" }} className="contact-button">
              <a href="/" style={{ textDecoration: "none", color: "white" }}>Takaisin etusivulle</a>
            </button>

        </section>
      </main>

      <footer className="footer">
        <svg className="wave2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="rgb(0,0,66)" fillOpacity="1" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
        <div className='footer-texts'>
          <p>&copy; 2024 Eristyspalvelu Kaasalainen. All rights reserved.</p>
          <p><a style={{textDecoration:"underline", color: "white", cursor: "pointer"}} onClick={showCookieConsentBanner}>Muuta evästeasetuksia</a></p>
          <a href="https://www.tinkerit.fi" target="_blank" rel="noopener noreferrer" className="footer-link">
            <span>Website by TinkerIT</span>
            <img src={lamppu} alt="TinkerIT Logo" className="footer-lamppu" />
          </a>
        </div>
      </footer>
    </>
  );
}

export default Yhteystiedot;
