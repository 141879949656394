import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import headerImage from './Helsinki.jpeg';
import luotettavaKumppani from './luotettava_kumppani_logo.jpg';
import eristysKuva from './1_mahis.jpg';
import lamppu from './lamppu2.png'
import Yhteystiedot from './Yhteystiedot.js';
import CookieConsent, { showCookieConsentBanner } from './CookieConsent';  // Updated import
import ReactGA from 'react-ga4';  // Import GA4


function App() {
  useEffect(() => {
    ReactGA.initialize('G-4PG4WM9RWX');  // Initialize with your Measurement ID
    ReactGA.send('pageview'); // Track the initial pageview
  }, []);

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainPage scrollToSection={scrollToSection} />} />
          <Route path="/yhteystiedot" element={<Yhteystiedot />} />
        </Routes>
        <CookieConsent />
      </div>
    </Router>
  );
}

function MainPage({ scrollToSection }) {
  return (
    <>
      <header className="header" style={{ backgroundImage: `url(${headerImage})`}} alt="Uudenmaan eristys">
        {/* Header with image background */}
        <div className="header-content">
          <h1>Eristyspalvelu Kaasalainen Oy</h1>
          <p>Lvi-alan Ammattilainen</p>
        </div>
        <button 
          className='header-button' 
          onClick={() => scrollToSection('ota-yhteyttä')}
        >
          Ota yhteyttä
        </button>
        <svg className="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="rgb(255, 255, 255)" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
      </header>

      <main className="main-content">
        {/* Information about the company */}
        <section className="company-info-wrapper">
          <div className="company-info">
            <h2>Tietoa Meistä</h2>
            <ul>
              <li>Toimintaamme kuuluu <strong>kaikki</strong> putki- ja iv-alan eristystyöt.</li>
              <li>Henkilöstöömme kuuluu tällä hetkellä n. 15 kokenutta ammattilaista.</li>
              <li>Annamme mielellämme tarjouksen kaikista, niin pienistä kuin isoista eristyskohteista. Tarjoamme aina kilpailukykyisen hinnan. Laadusta tinkimättä.</li>
              <li>Yrityksemme on myös <strong>tilaajavastuu.fi</strong> luotettava kumppani</li>
            </ul>
          </div>

          {/* Image */}
          <div className='image-wrapper'>
            <div className='company-info-2 eristysKuva'>
              <img src={eristysKuva} alt="LVI eristystyö" />
            </div>
            <div className='company-info-2 luotettavaKumppani'>
              <img src={luotettavaKumppani} alt="Luotettavakumppani.fi" />
            </div>
          </div>
        </section>

        <div className="company-info-mobile">
          <h2 style={{ fontSize: "42px" }}>Tietoa Meistä</h2>
          <p style={{ fontSize: "22px", marginTop: "50px", marginLeft: "5%", marginRight: "5%" }}>Toimintaamme kuuluu <strong>kaikki</strong> putki- ja iv-alan eristystyöt.</p>
          <p style={{ fontSize: "22px", marginTop: "50px", marginLeft: "5%", marginRight: "5%" }}>Henkilöstöömme kuuluu tällä hetkellä n. 15 kokenutta ammattilaista.</p>
          <p style={{ fontSize: "22px", marginTop: "50px", marginLeft: "5%", marginRight: "5%" }}>Annamme mielellämme tarjouksen kaikista, niin pienistä kuin isoista eristyskohteista. Tarjoamme aina kilpailukykyisen hinnan. Laadusta tinkimättä.</p>
          <p style={{ fontSize: "22px", marginTop: "50px", marginLeft: "5%", marginRight: "5%" }}>Yrityksemme on myös <strong>tilaajavastuu.fi</strong> luotettava kumppani</p>
        </div>

        <div className='image-wrapper-mobile'>
          <div className='company-info-2 eristysKuva'>
            <img src={eristysKuva} alt="LVI eristystyö" />
          </div>
          <div className='company-info-2 luotettavaKumppani'>
            <img src={luotettavaKumppani} alt="Luotettavakumppani.fi" />
          </div>
        </div>

        {/* Contact information */}
        <section className="contact-info">
          <div className="contact-info-details">
            <h2>Yhteystiedot</h2>
            <address>
              <p><strong>Eristyspalvelu Kaasalainen Oy</strong><br />
                <strong>Y-Tunnus:</strong> 2538016-9<br />
                Taipaleentie 139<br />
                16540 Mertie<br /></p>

              <hr />

              <p>
                <strong>Jarno Kaasalainen</strong><br />
                <a href="tel:0401620404" style={{ textDecoration: "none", color: "black" }}>040 162 0404</a><br />
                <a href="mailto:info@eristys.fi" style={{ textDecoration: "none", color: "black" }}>info@eristys.fi</a>
              </p>

              <hr />

              <button className="contact-button">
                <a href="/yhteystiedot" style={{ textDecoration: "none", color: "white" }}>Kaikki yhteystiedot</a>
              </button>
            </address>
          </div>
          <div className='contact-info-Maps'>
            <iframe title='Maps' width="100%" height="400px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=400px&amp;hl=en&amp;q=Taipaleentie%20139%2016540%20Mertie+(Eristyspalvelu%20Kaasalainen%20Oy)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps devices</a></iframe>
          </div>
        </section>

        <section id='ota-yhteyttä'>
          <div className="contact-info-details-mobile" style={{ lineHeight: "1.5" }}>
            <h2 style={{ fontSize: "42px" }}>Yhteystiedot</h2>
            <address>
              <p style={{ fontSize: "24px" }}><strong>Eristyspalvelu Kaasalainen Oy</strong><br />
                <strong>Y-Tunnus:</strong> 2538016-9<br />
                Taipaleentie 139<br />
                16540 Mertie<br /></p>

              <hr />

              <p style={{ fontSize: "24px", lineHeight: "1.5" }}>
                <strong>Jarno Kaasalainen</strong><br />
                <a href="tel:0401620404" style={{ textDecoration: "none", color: "black" }}>040 162 0404</a><br />
                <a href="mailto:info@eristys.fi" style={{ textDecoration: "none", color: "black" }}>info@eristys.fi</a>
              </p>

              <hr />

              <button style={{ height: "3em", fontSize: "24px", marginTop: "10%", marginBottom: "10%", borderRadius: "50px" }} className="contact-button">
                <a href="/yhteystiedot" style={{ textDecoration: "none", color: "white" }}>Kaikki yhteystiedot</a>
              </button>
            </address>
          </div>
          {/* Add more contact information as needed */}
          <div className='contact-info-Maps-mobile'>
            <iframe title='Maps' width="90%" height="300px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=400px&amp;hl=en&amp;q=Taipaleentie%20139%2016540%20Mertie+(Eristyspalvelu%20Kaasalainen%20Oy)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps devices</a></iframe>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="footer">
        <svg className="wave2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="rgb(0,0,66)" fillOpacity="1" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
        <div className='footer-texts'>
          <p>&copy; 2024 Eristyspalvelu Kaasalainen. All rights reserved. </p>
          <p><a style={{textDecoration:"underline", color: "white", cursor: "pointer"}} onClick={showCookieConsentBanner}>Muuta evästeasetuksia</a></p>
          <a href="https://www.tinkerit.fi" target="_blank" rel="noopener noreferrer" className="footer-link">
            <span>Website by TinkerIT</span>
            <img src={lamppu} alt="TinkerIT Logo" className="footer-lamppu" />
          </a>
        </div>
      </footer>
    </>
  );
}


export default App;
